<template>
    <div class="rawMaterialInfo">
        <div class="table el_left">
            <div class="tableHeader">
                <div class="el_left">
                    <button
                        type="button"
                        class="el-button btns el-button--default el-button--mini"
                        @click="addToRawMaterial"
                    >
                        <span><i class=""></i> 新增 </span>
                    </button>
                    <button
                        type="button"
                        class="el-button btns el-button--default el-button--mini"
                        @click="batchDeletion"
                    >
                        <span><i class=""></i> 删除 </span>
                    </button>
                </div>
            </div>
            <div class="table-bg">
                <el-table
                    :data="tableData"
                    stripe
                    style="width: 100%"
                    @selection-change="handleSelectionChange"
                >
                    <el-table-column
                        label="选择"
                        type="selection"
                        width="55"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="material_type"
                        label="原料类型"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="material_name"
                        label="原料名称"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="brand"
                        label="品牌"
                    >
                    </el-table-column>
                    <el-table-column
                        label="标准用量"
                    >
                        <template slot-scope="scope">
                            <input
                                type="text"
                                class="formula"
                                v-model="scope.row.formula"
                                @change="ratioCalculation"
                            >
                        </template>
                    </el-table-column>
                    <!-- <el-table-column
                        prop="address"
                        label="调整范围"
                    >
                    </el-table-column> -->
                    <el-table-column
                        prop="address"
                        label="操作"
                    >
                        <template slot-scope="scope">
                            <el-button
                                size="mini"
                                type="danger"
                                class="iconfont delete iconshanchu"
                                @click="deleteRawMaterial(scope.row, scope.$index)"
                            >
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <ul class="formula el_left">
            <li>
                <div>设计容量</div>
                <p>
                    <span>{{ standardFormula.design_volume_weight }}</span>kg
                </p>
            </li>
            <li>
                <div>调整容量</div>
                <p>
                    <span>{{ standardFormula.tuneup_volume_weight }}</span>kg
                </p>
            </li>
            <li>
                <div>水胶比</div>
                <p>
                    <span>{{ standardFormula.water_binder_ratio }}</span>%
                </p>
            </li>
            <li>
                <div>砂率</div>
                <p>
                    <span>{{ standardFormula.sand_rate }}</span>kg
                </p>
            </li>
        </ul>
    </div>
</template>

<script>

export default {
    // 基础配比库——原料信息
    name: 'raw-material-info',
    components: {},
    props: {
        standardFormula: {
            type: [Array, Object, String],
        },
        tabData: {
            type: [Array, Object, String],
        },
    },
    data() {
        return {
            // 原料列表数据集合
            tableData: [],
            // 多选数据集合
            tableSelectData: '',
        };
    },
    watch: {
        tabData(newVal) {
            this.tableData = newVal;
        },
    },
    computed: {},
    methods: {
        // 新增原料
        addToRawMaterial() {
            this.$toast({
                title: true,
                text: '添加原料',
                type: 'eject',
                width: '16rem',
                t_url: 'tableList/index',
                extr: {
                    code: {
                        TableCode: 'PBYLXX',
                        selectedCallback: this.confirmSelection,
                    },
                },
            });
        },
        // 确认选择，更新原料列表
        confirmSelection(data) {
            data.forEach(item => {
                // 新增原料数据
                const newRawMaterialData = {
                // 配比编号
                    formula_number: '',
                    // 原材类型 1 粉料 2 外加剂 3 砂 4 石 5 膨胀剂 6 水
                    material_type: item.material_type,
                    // 原材类型名称
                    material_type_name: item.material_type_name,
                    // 原材料编码
                    material_code: item.material_code,
                    // 原材料名称
                    material_name: item.material_name,
                    // 原材料规格、型号
                    material_model: item.material_model,
                    // 单价
                    price: 0,
                    // 配比值
                    formula: 0,
                    // 配比值上限
                    formula_upper: 0,
                    // 配比值下限
                    formula_lower: 0,
                    // 配料单别名
                    formula_alias: '',
                    // 生产线
                    product_line: '',
                    // 品牌
                    brand: item.brand,
                    // 创建时间
                    created: item.created,
                    // 排序
                    sort_num: 0,
                };
                // 新增原料
                this.tableData.push(newRawMaterialData);
            });

            // 配比计算
            this.ratioCalculation();
        },
        // 多选选中数据集合
        handleSelectionChange(row) {
            this.tableSelectData = row;
        },
        // 批量删除原料
        batchDeletion() {
            this.deleteRawMaterial(this.tableSelectData);
        },
        // 删除原料
        deleteRawMaterial(data) {
            let newData = [];
            // 已保存原料集合
            const postCode = [];
            if (!Array.isArray(data)) {
                newData.push(data);
            } else {
                newData = data;
            }
            newData.forEach(item => {
                if (item.formula_number !== '') {
                    postCode.push({
                        // 配比编号
                        formula_number: item.formula_number,
                        // 原材料编码
                        material_code: item.material_code,
                    });
                } else {
                    this.tableData.forEach((k, i) => {
                        if (item.material_code === k.material_code) {
                            this.tableData.splice(i, 1);
                        }
                    });
                }
            });
            if (postCode.length > 0) {
                this.$axios
                    .post('/interfaceApi/production/standardformula/deleteformulaentity', postCode)
                    .then(() => {
                        newData.forEach(item => {
                            this.tableData.forEach((k, i) => {
                                if (item.material_code === k.material_code) {
                                    this.tableData.splice(i, 1);
                                }
                            });
                        });
                        this.$message({
                            message: '删除成功！',
                            type: 'success',
                        });
                    })
                    .catch(error => {
                        this.$message.error(error.ErrorCode.Message);
                    });
            }

        },
        // 配比计算
        ratioCalculation() {
            this.$axios
                .post('/interfaceApi/production/formulacalculate/calculate', this.tableData)
                .then(res => {
                    // 水泥重量
                    this.standardFormula.cement_weight = res.CementWeight;
                    // 掺合料重量
                    this.standardFormula.admixture_weight = res.AdmixtureWeight;
                    // 细骨料重量
                    this.standardFormula.fine_aggregate_weight = res.FineAggregateWeight;
                    // 粗骨料重量
                    this.standardFormula.coarse_aggregate_weight = res.CoarseAggregateWeight;
                    // 胶凝材料重量
                    this.standardFormula.CementitiousMaterialWeight = res.CementitiousMaterialWeight;
                    // 水胶比
                    this.standardFormula.water_binder_ratio = res.WaterBinderRatio;
                    // 砂率
                    this.standardFormula.sand_rate = res.SandRate;

                    this.$emit('selectRawMaterialCallback', this.standardFormula);
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
    },
    created() {},
    mounted() {},
};
</script>
<style lang="stylus">
    .rawMaterialInfo
        height 100%
        .table
            width calc(100% - 2.6rem)
            height 100%
            margin-right 0.2rem
            .tableHeader
                height 0.28rem
                margin-bottom 0.14rem
                .el-button
                    color #fff
                    background #2978ff
                    border-radius 2px
            .table-bg
                height calc(100% - 0.42rem)
                .el-table
                    height 100%
                    .formula
                        width 1.22rem
                        height 0.22rem
                        color #333333
                        padding-left 0.06rem
                        border 1px solid #E2EBF0
                    .el-button
                        background none
                        color #D5000F
                        border none
        .formula
            width 2.4rem
            li
                float left
                width 100%
                height 0.58rem
                border 1px solid #D7D7D7
                margin-bottom 0.15rem
                div,p
                    float left
                    text-align center
                    line-height 0.56rem
                div
                    width 1rem
                    font-size 0.18rem
                p
                    width calc(100% - 1rem)
                    span
                        font-size 0.32rem
                        padding-right 0.05rem
                &:nth-of-type(1)
                    div
                        color rgba(85, 136, 241, 1)
                        background rgba(85, 136, 241, 0.1)
                    p
                        span
                            color rgba(85, 136, 241, 1)
                &:nth-of-type(2)
                    div
                        color rgba(235, 101, 111, 1)
                        background rgba(235, 101, 111, 0.1)
                    p
                        span
                            color rgba(235, 101, 111, 1)
                &:nth-of-type(3)
                    div
                        color rgba(33, 189, 204, 1)
                        background rgba(33, 189, 204, 0.1)
                    p
                        span
                            color rgba(33, 189, 204, 1)
                &:nth-of-type(4)
                    div
                        color rgba(250, 132, 54, 1)
                        background rgba(250, 132, 54, 0.1)
                    p
                        span
                            color rgba(250, 132, 54, 1)
                &:nth-of-type(5)
                    div
                        color rgba(139, 107, 238, 1)
                        background rgba(139, 107, 238, 0.1)
                    p
                        span
                            color rgba(139, 107, 238, 1)
                &:nth-of-type(6)
                    div
                        color rgba(31, 180, 163, 1)
                        background rgba(31, 180, 163, 0.1)
                    p
                        span
                            color rgba(31, 180, 163, 1)
</style>
